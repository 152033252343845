<template>
  <d-dropdown-form
    :hide="hide"
    :show="show"
    :right="true"
    :identifier="identifier"
  >
    <template v-slot:dropdownbutton>
      <d-button
        text="general.actions.actions"
        icon="icofont icofont-navigation-menu"
        class="btn pl-2 pt-1 pb-1 pr-2 d-btn-default font-text-title"
        @on:button-click="show = !show"
      />
    </template>
    <template v-slot:dropdownbody>
      <a
        href="#"
        class="dropdown-item"
        @click="onDueDateShowClick"
      >
        <div
          class="simple-label-avenir pointer"
        >
          <i class="icofont icofont-calendar pr-2"></i>
          {{  $t('views.client.details.body.left.subscription-tab.show-due-date') }}
        </div>
      </a>
      <div class="border-bottom"></div>
      <a
        href="#"
        class="dropdown-item"
        @click="onSubscriptionCancelClick"
      >
        <div
          class="simple-label-avenir pointer"
        >
          <i class="icofont icofont-trash pr-2"></i>
          {{  $t('views.client.details.body.left.subscription-tab.cancel-subscription-card') }}
        </div>
      </a>
    </template>
  </d-dropdown-form>
</template>
<script>
const SUBSCRIPTION_CARD_IDENTIFIER = 'subscription-card-identifier';

export default {
  data: () => ({
    hide: false,
    show: false,
  }),
  props: {
    identifier: {
      type: String,
      default: SUBSCRIPTION_CARD_IDENTIFIER
    }
  },
  methods: {
    hideSubMenus () {
      this.hide = !this.hide;
    },
    onDueDateShowClick () {
      this.hideSubMenus();
      this.$emit('on:due-date:show');
    },
    onSubscriptionCancelClick () {
      this.hideSubMenus();
      this.$emit('on:subscription-card:cancel');
    }
  }
}
</script>

<style lang="scss" scoped>
.middle-icon {
  vertical-align: middle;
}
/deep/ .dropdown-menu {
  min-width: auto;

  &.show {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  > li {
    line-height: 35px;

    &:not(:last-child) {
      border-bottom: 1px solid #DDD;
    }
  }
}
/deep/ .dropdown-item {
  padding: 0.5rem 1em;

  &:focus {
    color: #16181b;
    text-decoration: none;
    background-color: unset;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background-color: #919194;
  }
  &:hover {
    background-color: #EDEFF2;
  }
}
</style>>
