<template>
  <div class="ml-4">
    <div class="d-flex align-items-center">
      <div
        class="pointer width-max-content text-title-font-size mr-2"
        @click="progress = !progress"
      >
        {{ subscriptionCard.name }}
      </div>
      <div class="width-max-content mr-2">
        <div :class="backgroundStatus">
          <p class="text-center text-font-size">
            {{ getStatus() }}
          </p>
        </div>
      </div>
      <div class="w-100 border-bottom-subscription mr-1">
      </div>
      <div>
        <feather
          @click="progress = !progress"
          class="background-arrow pointer"
          :type="progress ? 'chevron-up' : 'chevron-down'"
        >
        </feather>
      </div>
    </div>
    <b-collapse
      v-model="progress"
      accordion="myaccordion"
      role="tabpanel"
    >
      <div class="mt-custom">
        <b-card-text>
          <b-row no-gutters>
            <b-col align="right">
              <div class="d-flex justify-content-center mt-2">
                <export-pdf
                  :btn="btnAttrs"
                  :extra="{client: client, subscriptionCard: subscriptionCard}"
                  target="subscription_contract"
                />
                <d-button
                  text="views.client.details.body.left.subscription-tab.show-due-date"
                  icon="icofont icofont-calendar"
                  class="btn pl-2 pt-1 pb-1 pr-2 d-btn-default font-text-title"
                  @on:button-click="displayDueDateModal = !displayDueDateModal"
                />
                <d-button
                  v-if="!subscriptionCard.canceled"
                  text="views.client.details.body.left.subscription-tab.cancel-subscription-card"
                  icon="icofont icofont-trash"
                  class="btn pl-2 ml-3 pt-1 pb-1 pr-2 d-btn-default-danger font-text-title"
                  @on:button-click="displayDeleteModal = !displayDeleteModal"
                />
                <!--
                <subscription-tab-menus
                  :identifier="subscriptionCard.id"
                  @on:due-date:show="displayDueDateModal = !displayDueDateModal"
                  @on:subscription-card:cancel="displayDeleteModal = !displayDeleteModal"
                />
                -->
                <d-modal
                  :modalId="'subscriptionCard' + subscriptionCard.id"
                  :display="displayDeleteModal"
                  text="views.subscriptions.remove-subscription-card"
                  title="views.subscriptions.remove-subscription-card-title"
                  :hide="hide"
                  @on:cancel="onCancel"
                  @on:confirm="onConfirm"
                />
                <due-date-modal
                  :modal-id="'subscription-card' + subscriptionCard['@id']"
                  :display="displayDueDateModal"
                  :client="client"
                  :subscription-card="subscriptionCard"
                />
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="subscriptionCard.plan.description && subscriptionCard.plan.description.trim().length"
            class="mt-4"
            no-gutters
          >
            <b-col cols="2">
              <span class="d-bg-light-blue icon-block-size"><i class="icon-info-alt"></i></span>
            </b-col>
            <b-col class="text-block">
              <b-row>
                {{ subscriptionCard.plan.description }}
              </b-row>
            </b-col>
          </b-row>
          <!-- TODO for 3.1 v
          <b-row
              no-gutters
              class="mt-4"
          >
            <b-col cols="2">
              <span class="d-bg-light-blue icon-block-size"><i class="icon-key"></i></span>
            </b-col>
            <b-col class="text-block">
              Réservation gratuite pour un terrain de Squash en heure creuse. Limité à 2 réservations par semaine. Accès compris au sauna.
            </b-col>
          </b-row>
          -->
          <b-row
            no-gutters
            class="mt-4"
          >
            <b-col cols="2">
              <span class="d-bg-light-blue icon-block-size">
                  <i class="icon-money"></i>
              </span>
            </b-col>
            <b-col class="text-block">
              <b-row>
                {{ subscriptionCard.planPrice.label }} - {{ subscriptionCard.planPrice.price / 100 }} {{ $currency }} /
                {{ commitmentPeriodicityType }}
              </b-row>
              <b-row class="renewal-title">
                {{
                  subscriptionCard.commitmentDurationCount ?
                    $t('views.client.details.body.left.subscription-tab.engage-automatic-renewal') +
                    ' '
                    +
                    subscriptionCard.commitmentDurationCount +
                    ' '
                    + commitmentPeriodicityTypeEngagement.toLowerCase() + ' -' : $t('views.client.details.body.left.subscription-tab.without-engagement') + ' -'
                }}
                {{ renewal }}
              </b-row>
            </b-col>
          </b-row>
          <b-row
            no-gutters
          >
            <b-col
              align="center"
              cols="12"
              class="border-bottom-left-body"
            ></b-col>
          </b-row>
        </b-card-text>
        <b-card-text class="custom-margin-bottom">
          <b-row no-gutters>
            <b-col>
              <div
                v-if="subscriptionCard.startDate || subscriptionCard.endDate"
                class="pull-right"
                @click="display = !display"
              >
                <span>
                  <i class="d-icon-gray icofont icofont-ui-edit btn-close-size pointer"/>
                </span>
                <subscriptions-dates-modal
                  :modal-id="'modal-id' + subscriptionCard.id"
                  :subscription-card="subscriptionCard"
                  :display="display"
                  @on:subscription-card-update="$emit('on:subscription-card-update')"
                />
              </div>
            </b-col>
          </b-row>
          <b-row
            no-gutters
            align="center"
          >
            <b-col>
              <div class="row mt-2 justify-content-start">
                <div v-if="subscriptionCard.startDate" class="col-6">
                  <div class="row">
                    <div :class="'col-' + colXTo4(3)">
                    <span class="d-bg-light-blue icon-block-size">
                        <i class="icon-calendar"></i>
                    </span>
                    </div>
                    <div :class="'col-' + colXTo12(0)">
                      <div class="text-block">
                        {{ $t('views.client.details.body.left.subscription-tab.startDate') }}
                      </div>
                      <div class="text-left-body-content">
                        {{ formatDate(subscriptionCard.rootStartDate || subscriptionCard.startDate) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="subscriptionCard.endDate" class="col-6">
                  <div class="row">
                    <div :class="'col-' + colXTo4(3)">
                    <span class="d-bg-light-blue icon-block-size">
                      <i class="icon-clipboard"></i>
                    </span>
                    </div>
                    <div :class="'col-' + colXTo12(0)">
                      <div class="text-block">
                        {{ $t('views.client.details.body.left.subscription-tab.endDate') }}
                      </div>
                      <div class="text-left-body-content">
                        {{ formatDate(subscriptionCard.endDate) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="Payment-section"
              >
                <div class="row mt-3 justify-content-start">
                  <div class="col-6">
                    <div class="row">
                      <div :class="'col-'+colXTo4(3)">
                        <span class="d-bg-light-blue icon-block-size">
                          <i class="fa fa-money"></i>
                        </span>
                      </div>
                      <div :class="'col-'+colXTo12(0)">
                        <div class="text-block">
                          {{ $t('views.client.details.body.left.subscription-tab.next-payment') }}
                        </div>
                        <div class="text-left-body-content">
                          {{ subscriptionCard.nextPaymentDate ? nextPaymentDate : $t('general.actions.undefined') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div :class="'col-'+colXTo4(3)">
                      <span class="d-bg-light-blue icon-block-size">
                          <i class="fa fa-credit-card-alt">
                          </i>
                      </span>
                      </div>
                      <div :class="'col-'+colXTo12(0)">
                        <div class="text-block">
                          {{ $t('views.client.details.body.left.subscription-tab.payment-mode') }}
                        </div>
                        <div
                          v-if="subscriptionCard.paymentMethod === 'manual_sepa_debit'"
                          class="text-left-body-content"
                        >
                          {{ $t('views.client.details.body.left.subscription-tab.manual') }}
                        </div>
                        <div
                          v-else-if="subscriptionCard.stripeSepaDebit"
                          class="text-left-body-content"
                        >
                          {{ 'SEPA °°°°' + subscriptionCard.stripeSepaDebit.last4 }}
                        </div>
                        <div
                          v-else
                          class="text-left-body-content"
                        >
                          {{ $t('views.client.details.body.left.subscription-affect.other') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-text>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import SubscriptionsDatesModal from "@custom/clients/subscriptions-tab/SubscriptionsDatesModal";
import {refine} from "@/utils/classes";
import {
  getSubscriptionContract,
  putSubscriptionCard
} from "@api/doinsport/services/subscription-card/subscription-card.api";
import DueDateModal from "@custom/clients/subscriptions-tab/DueDateModal";
import SubscriptionTabMenus from "@custom/clients/subscriptions-tab/SubscriptionTabMenus";
import ExportPdf from "@custom/ExportPdf";

export default {
  components: {ExportPdf, SubscriptionTabMenus, DueDateModal, SubscriptionsDatesModal},
  props: {
    subscriptionCard: {
      type: Object,
      default: () => {
      },
    },
    client: {
      type: Object,
      default: () => {
      },
    }
  },
  data: () => ({
    display: false,
    hide: false,
    displayDeleteModal: false,
    displayDueDateModal: false,
    progress: true,
    lastMonth: false,
    expired: false,
    innerWidth: 1600,
  }),
  computed: {
    btnAttrs() {
      return {
        label: 'views.client.details.body.left.subscription-tab.create-contract',
        icon: 'icofont icofont-download-alt',
        class: 'btn pl-3 pt-1 pb-1 pr-3 mr-3 d-btn-default font-text-title'
      }
    },
    nextPaymentDate() {
      return this.$moment.utc(this.subscriptionCard.nextPaymentDate).format('DD/MM/YYYY');
    },
    renewal() {
      return this.subscriptionCard.automaticRenewal ? this.$t('views.client.details.body.left.subscription-tab.with-automatic-renewal') : this.$t('views.client.details.body.left.subscription-tab.without-automatic-renewal');
    },
    commitmentPeriodicityType() {
      if (parseInt(this.subscriptionCard.planPrice.dueDateGap) === 1) {
        return this.getDueDateGapTypeLabel();
      } else if ((parseInt(this.subscriptionCard.planPrice.dueDateGap) > 1) && this.subscriptionCard.planPrice.dueDateGapType === 'week') {
        return ' ' + this.subscriptionCard.planPrice.dueDateGap + ' ' + this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType + '.plural');
      } else {
        return ' ' + this.subscriptionCard.planPrice.dueDateGap + ' ' + this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType);
      }
    },
    commitmentPeriodicityTypeEngagement() {
      const dueDateGapType = parseInt(this.subscriptionCard.planPrice.dueDateGap) === 1 ?
        this.getDueDateGapTypeLabelEngagement()
        :
        ' ' + this.subscriptionCard.planPrice.dueDateGap + '  ' + this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType);
      return dueDateGapType;
    },
    align() {
      return this.innerWidth <= 1200 ? 'center' : 'left';
    },
    status() {
      if (this.subscriptionCard.canceled) {
        return 'canceled'
      } else if (this.$moment.utc(this.subscriptionCard.startDate).isAfter(this.$moment.utc())) {
        return 'programmed'
      }

      const diffNowEndDateAsDay = this.$moment.utc(this.subscriptionCard.endDate).diff(this.$moment.utc(), 'days');

      if (diffNowEndDateAsDay > 30) {
        return 'progress';
      } else if (diffNowEndDateAsDay <= 30 && diffNowEndDateAsDay > 0) {
        return diffNowEndDateAsDay;
      } else if (diffNowEndDateAsDay <= 0) {
        const diffNowEndDateAsHour = this.$moment.utc(this.subscriptionCard.endDate).diff(this.$moment.utc(), 'hours');

        if (diffNowEndDateAsHour <= 0) {
          return 'expired';
        } else {
          return 'expire-in-couple-hours';
        }
      }
    },
    backgroundStatus() {
      switch (this.status) {
        case 'canceled':
          return 'background-progress-canceled';
        case 'progress':
          return 'background-progress';
        case 'programmed':
          return 'background-progress-programmed';
        case 'expired':
          return 'background-progress-expired';
        default:
          return 'background-progress-lastMonth';
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('DD/MM/YYYY');
    },
    onCancel() {
      this.hide = !this.hide;
    },
    onConfirm() {
      const clients = this.subscriptionCard.clients;
      let subscriptionCardClients = {clients: clients};
      refine(subscriptionCardClients);
      const customerIri = subscriptionCardClients.clients.find(el => el === '/clubs/clients/' + this.$route.params.id);
      subscriptionCardClients.clients.splice(subscriptionCardClients.clients.indexOf(customerIri), 1);

      putSubscriptionCard(this.subscriptionCard.id, {canceled: true})
        .then((response) => {
          this.hide = !this.hide;
          this.$emit('on:subscription-card-update');
        })
      ;
    },
    getDueDateGapTypeLabel() {
      if (this.subscriptionCard.planPrice.dueDateGapType === 'week') {
        return this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType + '.label');
      } else {
        return this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType);
      }
    },
    getDueDateGapTypeLabelEngagement() {
      if (this.subscriptionCard.planPrice.dueDateGapType === 'week') {
        return this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType + '.label');
      } else if (this.subscriptionCard.planPrice.dueDateGapType === 'year') {
        return this.$t('general.actions.month');
      } else {
        return this.$t('general.actions.' + this.subscriptionCard.planPrice.dueDateGapType);
      }
    },
    getCol(x) {
      return this.innerWidth <= 1200 ? '12' : x;
    },
    colXTo4(x) {
      if (this.innerWidth > 590 && this.innerWidth <= 1300) {
        return 5;
      }
      return this.innerWidth <= 580 ? '4' : x;
    },
    colXTo12(x) {
      return this.innerWidth <= 580 ? '12' : x;
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    getStatus() {
      switch (this.status) {
        case 'progress':
          return this.$t('general.actions.global-status.progress');
        case 'expired':
          return this.$t('general.actions.global-status.expired');
        case 'canceled':
          return this.$t('general.actions.global-status.canceled');
        case 'programmed':
          return this.$t('general.actions.global-status.programmed');
        case 'expire-in-couple-hours':
          return this.$t('general.actions.global-status.expire-in-couple-hours');
        default :
          return this.$t('general.actions.global-status.expire-soon') + ' (' + this.status + this.$t('general.actions.shortDay') + ')';
      }
    }
  },
  created() {
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/client/details/_subscription-tab.scss";
</style>
