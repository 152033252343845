<template>
  <d-button
    :text="btn.label"
    :icon="btnLoading ? 'fa fa-spinner fa-spin' : btn.icon"
    :class="btnLoading ? btn.class + ' disabled' : btn.class"
    @on:button-click="exportToPdf"
  />
</template>
<script>
  import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";
  import {exportFormulas} from "@api/doinsport/services/bookings/booking.api";
  import {getSubscriptionContract} from "@api/doinsport/services/subscription-card/subscription-card.api";

  export default {
    data: () => ({
      fileName: 'general.actions.undefined',
      btnLoading: false,
    }),
    props: {
      btn: {
        type: Object,
        default: {
          label: 'general.actions.export',
          icon: 'fa fa-external-link mr-2 mt-1',
          class: 'd-btn btn d-btn-default font-text-title'
        }
      },
      extra: {
        type: Object,
        default: null
      },
      target: {
        type: String,
        default: null
      },
      date: {
        type: String,
        default: null
      }
    },
    methods: {
      getTarget () {
        const dateFilter = this.date === null ? '' : '&date=' + this.$moment(this.date).format(YEAR_DASH_MONTH_DASH_DAY);

        switch (this.target) {
          case 'formulas':
            this.fileName = this.target;

            return exportFormulas(dateFilter);
          case 'subscription_contract':
            this.fileName = this.extra.subscriptionCard.plan.name + '_' + this.extra.client.fullName;

            return getSubscriptionContract(this.extra.subscriptionCard.id);
          default: console.error('undefined target')
        }
      },

      exportToPdf() {
        if (false === this.btnLoading) {
          this.btnLoading = true;

          this.getTarget()
            .then((response) => {
              this.$downloadPDF(response, this.fileName, this.date);
            })
            .finally(() => this.btnLoading = false)
          ;
        }
      },
    },
  }
</script>
