<template>
  <b-modal
    :id="modalId"
    size="lg"
    hide-header
    hide-footer
    hide-header-close
    body-bg-variant="gray-lighten"
    header-class="my-second-class"
  >
    <div>
      <due-date-table
        :subscription-card="subscriptionCard"
        :export-pdf="exportPdf"
        :client="client"
      />
    </div>
    <div class="d-flex justify-content-center">
      <d-button
        text="general.actions.export"
        class="d-btn btn d-btn-default font-text-title"
        icon="fa fa-external-link mr-1"
        @on:button-click="exportPdf = !exportPdf"
      />
      <d-button
        text="general.actions.dismiss"
        class="d-btn btn d-btn-danger ml-4 font-text-title"
        @on:button-click="$bvModal.hide(modalId)"
      />
    </div>
  </b-modal>
</template>
<script>

const MODAL_ID = 'PRICE_MODAL_ID';

import DueDateTable from "@custom/clients/subscriptions-tab/DueDateTable";

export default {
  data: () => ({
    clubLogo: null,
    base64: null,
    exportPdf : false,
  }),
  components: {DueDateTable},
  props: {
    modalId: {
      type: String,
      default: MODAL_ID,
    },
    subscriptionCard: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: () => {
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";

@media (min-width: 992px) {
  /deep/ .modal-lg, .modal-xl {
    max-width: 900px;
  }
}

.class-background {
  background-image: url("https://amymhaddad.s3.amazonaws.com/morocco-blue.png");
  height: 400px;
  width: 100%;
}

</style>
