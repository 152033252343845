<template>
  <b-modal
      :id="modalId"
      size="lg"
      hide-header
      hide-footer
      hide-header-close
      body-bg-variant="gray-lighten"
      header-class="my-second-class"
  >
    <validation-observer
        ref="subscriptionsDatesObserver"
        slim
    >
      <b-row>
        <b-col align="left" class="modal-title-class">
          {{ $t('views.client.details.body.left.subscription-dates.label') }}
        </b-col>
      </b-row>
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row>
        <b-col cols="6">
          <label>
            {{ $t('views.client.details.body.left.subscription-dates.startingDate') }}
          </label>
        </b-col>
        <b-col cols="6">
          <label>
            {{ $t('views.client.details.body.left.subscription-dates.endingDate') }}
          </label>
        </b-col>
        <b-col cols="6">
          <div
              @click="updateStartingDate()"
              class="has-calendar"
          >
                <span
                    class="fa fa-calendar form-control-feedback">
                </span>
            <b-form-input
                :value="startingDate | moment('DD/MM/YYYY')"
                readonly
                class="background-light-blue-inputs pointer"
                size="sm"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <div @click="displayEndDateCalendar =! displayEndDateCalendar" class="has-calendar">
                <span
                    class="fa fa-calendar form-control-feedback">
                </span>
            <b-form-input
                :value="endingDate | moment('DD/MM/YYYY')"
                readonly
                class="background-light-blue-inputs pointer"
                size="sm"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="startingDateUpdateErrorMessage !== null">
        <b-col cols="6" class="text-danger font-card-size-sub-title">
          {{ $t(startingDateUpdateErrorMessage) }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col align="center">
          <d-button
              text="general.actions.save"
              class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
              @on:button-click="onSave"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <d-calendar-modal
      identifier="startDate"
      :show="displayStartDateCalendar"
      @on:calendar-change="onStartDateChange"
    />
    <d-calendar-modal
        identifier="endDate"
        :show="displayEndDateCalendar"
        :filterFromDate="$moment.utc(this.startingDate).tz($store.getters['auth/currentClub'].timezone)"
        @on:calendar-change="onEndDateChange"
    />
  </b-modal>
</template>
<script>

import {putSubscriptionCard} from "@api/doinsport/services/subscription-card/subscription-card.api";
import moment from "moment-timezone";

const MODAL_ID = 'CATEGORY_MODAL_ID';

export default {
  data: () => ({
    items: [],
    name: null,
    startingDateUpdateErrorMessage: null,
    displayStartDateCalendar: false,
    displayEndDateCalendar: false,
    startingDate: null,
    endingDate: null,
    description: null,
    validation: require('@validation/entities/doinsport/SubscriptionPlan.json')
  }),
  props: {
    modalId: {
      type: String,
      default: MODAL_ID,
    },
    subscriptionCard: {
      type: Object,
      default: () => {},
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateStartingDate() {
      if (this.subscriptionCard.stripeSubscriptionReference !== null) {
        this.startingDateUpdateErrorMessage = 'views.client.details.body.left.subscription-dates.cannot-update-stripe-starting-date';
      } else if (this.subscriptionCard.rootStartDate !== null) {
        this.startingDateUpdateErrorMessage = 'views.client.details.body.left.subscription-dates.cannot-update-renewed-starting-date';
      } else {
        this.displayStartDateCalendar = !this.displayStartDateCalendar;
      }
    },
    onSave () {
      if (this.subscriptionCard.stripeSubscriptionReference === null) {
        putSubscriptionCard(this.subscriptionCard.id, { startDate: this.startingDate, endDate: this.endingDate })
          .then((response) => {})
          .finally(() => {
            this.$bvModal.hide(this.modalId);
            this.$emit('on:subscription-card-update');
          })
      } else {
        putSubscriptionCard(this.subscriptionCard.id, { endDate: this.endingDate })
          .then((response) => {})
          .finally(() => {
            this.$emit('on:subscription-card-update');
            this.$bvModal.hide(this.modalId);
          })
      }
    },
    onStartDateChange (date) {
      this.startingDate = this.$moment.utc(date)
        .tz(this.$store.getters['auth/currentClub'].timezone).format('YYYY-MM-DD');
    },
    onEndDateChange (date) {
      this.endingDate = this.$moment.utc(date)
        .tz(this.$store.getters['auth/currentClub'].timezone).format('YYYY-MM-DD');
    }
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    }
  },
  created() {
    this.startingDate = this.$moment.utc(this.subscriptionCard.rootStartDate || this.subscriptionCard.startDate)
      .tz(this.$store.getters['auth/currentClub'].timezone).format('YYYY-MM-DD');
    this.endingDate = this.$moment.utc(this.subscriptionCard.endDate)
      .tz(this.$store.getters['auth/currentClub'].timezone).format('YYYY-MM-DD');
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";

@media (min-width: 992px) {
  /deep/ .modal-lg, .modal-xl {
    max-width: 700px;
  }
}

</style>
